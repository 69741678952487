import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroSplit from "../components/Hero/HeroSplit";
import Services from "../components/Repeating/Services";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";
import IndustriesSidebar from "../components/Repeating/IndustriesSidebar";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   return (
      <Layout headerStyle="overlap" headerLinkColor="white" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Mass Transit Parts Manufacturer | Bus, Train, Plane Parts"
            description="When you need quality component production from a trusted manufacturer that’s worked with some of the top local mass transit companies, choose Painted Rhino."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroSplit
            hasContainer={false}
            backgroundImageWrapperWidth="1/2"
            textWrapperWidth="1/2"
            backgroundImages={data.heroDesktop.childImageSharp.fluid}
            backgroundImageSide="right"
            backgroundSizeDesktop="cover"
            backgroundSizeMobile="cover"
            backgroundPositionDesktop="50% 50%"
            backgroundPositionMobile="50% 50%"
            minHeightDesktop="704px"
            minHeightMobile="330px"
         >
            <h1>Mass Transit Component Manufacturer</h1>
            <p>
               When you need quality component production from a trusted manufacturer that’s worked with some of the top local mass transit companies,
               choose Painted Rhino. We’re a family-owned business with extensive industry expertise and a strong focus on innovation and creative
               solutions. You bring us your design, and we’ll manufacture it.
            </p>
            <ButtonSolid as="button" data="modal-contact" text="Get a Quote" className="w-full md:w-auto" />
         </HeroSplit>

         <section className="pb-18 md:pb-36">
            <div className="container">
               <div className="grid gap-y-12 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
                  <div className="md:col-span-8 md:col-start-1">
                     <div className="mb-12 md:mb-16">
                        <h2>Your Premier Parts Manufacturer</h2>
                        <p>
                           You get solid production—whether it’s multiple parts you need or simply a one-off custom piece for your mass transit
                           application. Our team can also manufacture a prototype and even aid in the design so that you can test a concept.
                        </p>
                     </div>

                     <div className="mb-12 md:mb-16">
                        <h3>Applications</h3>
                        <p>
                           Our team has decades of experience in <a href="https://www.paintedrhino.com/fiberglass-composite-parts-manufacturing/" className="text-[#006ee8]">composite</a>, <a href="https://www.paintedrhino.com/vacuum-forming-thermoforming-company/" className="text-[#006ee8]">vacuum forming</a>, and <a href="https://www.paintedrhino.com/injection-urethane-rotocasting/" className="text-[#006ee8]">urethane plastic manufacturing</a>, and we produce a broad
                           scope of mass transit components.
                        </p>
                     </div>

                     <div className="mb-12 md:mb-16">
                        <h3>Bus Component Manufacturing</h3>
                        <p>
                           Our team of experts produces high-quality, long-lasting bus components for fleets big and small, including, but not limited
                           to:
                        </p>
                        <ul className="styled-list">
                           <li>Driver Dashes</li>
                           <li>Overhead Instrument Panels</li>
                           <li>Front and Rear Caps</li>
                           <li>Fender Flares</li>
                           <li>Wheel Well Covers</li>
                           <li>Front and Rear Bumpers</li>
                           <li>Engine Doors</li>
                           <li>Tanks</li>
                           <li>Steps</li>
                           <li>Battery Trays</li>
                           <li>Engine Covers</li>
                           <li>Seating</li>
                           <li>Storage Doors & Bins</li>
                        </ul>
                     </div>

                     <div className="mb-12 md:mb-16">
                        <h3>Train Parts Manufacturing</h3>
                        <p>We’re also proud to manufacture components for train transport, such as commuter rails. Those parts include:</p>
                        <ul className="styled-list">
                           <li>Overhead Instrument Panels</li>
                           <li>Driver Dashes</li>
                           <li>Front and Rear Caps</li>
                           <li>Seating</li>
                           <li>Lavatories (Bathroom, Sinks, Shower Stalls, Cabinets)</li>
                        </ul>
                     </div>

                     <h3>Airplane Parts Manufacturing</h3>
                     <p>And you’ll also find our work in the skies. We produce items that air passengers use on every flight, such as:</p>
                     <ul className="styled-list">
                        <li>Overhead Compartments and Storage</li>
                        <li>Lavatories (Bathroom, Sinks, Shower Stalls, Cabinets)</li>
                     </ul>
                  </div>
                  <div className="md:col-end-13 lg:col-span-4">
                     <IndustriesSidebar />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <div className="grid gap-2 md:grid-cols-2 md:gap-4">
                  <div className="grid grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-6 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-flow-col grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs className="pb-20 md:pb-28" contentAlignment="md:text-center md:mx-auto" headerHeading="The Painted Rhino Difference." />

         <TestimonialBackgroundImage />

         <Services
            className="mb-22 pt-20 md:mb-32 md:pt-32"
            headerHeading="Our Manufacturing Processes"
            headerText="At Painted Rhino, you bring us your design and we’ll manufacture it. We’re experts in:"
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      heroDesktop: file(relativePath: { eq: "industries/Mass Transit/1.0 Hero Mass Transit.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 800) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-1.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/1 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-2.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/2 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-3.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/3 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-4.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/4 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-5.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/5 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-6.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/6 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "industries/Mass Transit/Gallery - Thumbnails/Mass Transit-7.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "industries/Mass Transit/Gallery- Lightbox/7 (1).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
